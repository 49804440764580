<template>
  <div id="login">
    <div class="banner"></div>
    <div class="loginArea">
      <div class="title">请完善用户信息</div>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="form.code" placeholder="请输入验证码">
            <template slot="append">
              <el-button :disabled="sended" @click="sendVerificationCode">{{
                sended ? `${countDown}秒后再次发送` : "发送验证码"
              }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="confirm" class="loginBtn">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { sendVerifyEmail, bindEmail } from "@/service/login";
import { Toast } from "vant";

export default {
  name: "Login",
  data() {
    return {
      form: {},
      countDown: 60,
      sended: false,
      logging: false,
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          bindEmail(this.form).then((res) => {
            if (res.success) {
              Toast("完善信息成功");
              setTimeout(() => {
                window.location.href = "/";
              }, 1500);
            } else {
              this.logging = false;
              Toast(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    userAgreement() {
      this.$router.push("/userAgreement");
    },
    sendVerificationCode() {
      if (!this.form.email || this.form.email == "") {
        return Toast("请输入邮箱!");
      }
      sendVerifyEmail(this.form.email).then((res) => {
        if (res.success) {
          Toast("发送验证码成功！");
          this.sended = true;
          const countDown = setInterval(() => {
            this.countDown = this.countDown - 1;
            if (this.countDown == 0) {
              clearInterval(countDown);
              this.countDown = 60;
              this.sended = false;
            }
          }, 1000);
        }
      });
    },
  },
  watch: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
#login {
  .banner {
    width: 100%;
    height: 200px;
    background: url(../assets/img/login/background-img.png) no-repeat;
    background-size: 100% 300%;
  }
  .loginArea {
    background: #fff;
    margin: 0 8px;
    transform: translateY(-82px);
    width: calc(100% - 48px);
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
    .title {
      font-size: 20px;
      padding: 24px;
      text-align: center;
    }
    .loginType {
      display: flex;
      width: 70%;
      margin: 0 15%;
      margin-top: 108px;
      margin-bottom: 52px;
      justify-content: space-between;
      img {
        background: #eee;
        vertical-align: top;
      }
      .wxLoginBtn,
      .passwordLoginBtn,
      .smsLoginBtn {
        border-radius: 50%;
        padding: 12px;
        overflow: hidden;
        background: #eee;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
    .loginBtn {
      background: linear-gradient(135deg, #fa6400 0%, #ffaf65 100%);
      border-radius: 6px 6px 6px 6px;
      width: 100%;
      color: #fff;
    }
  }
}
</style>